import React from "react"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

// Components
import { Checkmark, Tape, QuoteRight, QuoteLeft } from "../../icons"

const options = {
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => (
      <h1 className="text-3xl font-bold text-center uppercase md:text-5xl md:text-left siteFont">
        {children}
      </h1>
    ),
  },
}

const ProblemStatement = ({
  data: {
    caseStudyProblem,
    caseStudySolution,
    caseStudyResult,
    caseStudyTestimonial,
    caseStudyDeliverables,
    caseStudyTestimonialAuthor,
  },
}) => {
  return (
    <div className="container flex flex-col justify-center max-w-6xl px-4 pt-16 mx-auto text-white problemStatement md:flex-row md:pt-20 md:px-8 xl:px-0 md:items-center">
      <div className="w-full md:w-3/5">
        <div className="mb-4">
          <h1 className="mb-4 text-xl font-semibold text-left md:text-2xl siteFont">
            The Problem
          </h1>
          <p className="md:w-11/12 Gray">{caseStudyProblem.caseStudyProblem}</p>
        </div>
        <div className="mb-4">
          <h1 className="mb-4 text-xl font-semibold text-left md:text-2xl siteFont">
            The Solution
          </h1>
          <p className="md:w-11/12 Gray">
            {caseStudySolution.caseStudySolution}
          </p>
        </div>
        <div className="mb-4">
          <h1 className="mb-4 text-xl font-semibold text-left md:text-2xl siteFont">
            The Result
          </h1>
          <p className="md:w-11/12 Gray">{caseStudyResult.caseStudyResult}</p>
        </div>
      </div>
      <div className="w-full mt-8 md:w-2/5 md:mt-0">
        <div className="relative testimonial">
          {/* <Note className="relative w-full note" /> */}
          <div className="font-bold text-black text-md content text-primary">
            <p className="relative">
              {caseStudyTestimonial}
              <QuoteRight className="quoteRight" />
              <QuoteLeft className="quoteLeft" />
            </p>
            <p className="mt-2 italic text-black">
              {documentToReactComponents(caseStudyTestimonialAuthor.json)}
            </p>
          </div>
          <Tape className="tapeRight" />
          <Tape className="tapeLeft" />
        </div>
        <div className="mt-8 siteFont md:mt-0">
          <h1 className="my-4 text-xl uppercase md:text-3xl">
            <span className="doubleUnderline">
              What we Delivered
              <i></i>
              <i></i>
            </span>
          </h1>
          <ul className="flex flex-wrap">
            {caseStudyDeliverables.map(deliverable => (
              <li className="flex items-center w-1/2 my-2">
                <Checkmark />
                <p className="ml-2 text-lg capitalize">{deliverable}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ProblemStatement
