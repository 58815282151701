import React, { useContext } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

// Components
import Banner from "../components/Templates/CaseStudy/banner"
import ProblemStatement from "../components/Templates/CaseStudy/problemStatement"
import SectionBlock from "../components/Templates/CaseStudy/sectionBlock"
import Contact from "../components/Templates/CaseStudy/contactUs"
import CompanyHighlight from "../components/Templates/CaseStudy/companyHighlight"
import FeaturedWork from "../components/Templates/CaseStudy/featuredWork"
import ContactUs from "../components/contactUs"

// Context API data
import { AppContext } from "../store/AppContext"

const CaseStudyTemplate = ({ data }) => {
  const globalData = useContext(AppContext)
  const {
    ogTitle,
    ogDescription,
    image,
    caseStudyHeroTitle,
    caseStudyHeroImage,
    caseStudyProblem,
    caseStudySolution,
    caseStudyResult,
    caseStudyTestimonial,
    caseStudyDeliverables,
    caseStudyTestimonialAuthor,
    caseStudySection1Title,
    caseStudySection1Description,
    caseStudySection1Image,
    caseStudySection2Title,
    caseStudySection2Description,
    caseStudySection2Image,
    caseStudySection3Title,
    caseStudySection3Description,
    caseStudySection3Image,
    caseStudyFeaturedWork,
  } = data.content

  const bannerData = {
    caseStudyHeroTitle,
    caseStudyHeroImage,
  }
  const problemStatementData = {
    caseStudyProblem,
    caseStudySolution,
    caseStudyResult,
    caseStudyTestimonial,
    caseStudyDeliverables,
    caseStudyTestimonialAuthor,
  }
  const section1Data = {
    title: caseStudySection1Title,
    description: caseStudySection1Description,
    image: caseStudySection1Image,
  }
  const section2Data = {
    title: caseStudySection2Title,
    description: caseStudySection2Description,
    image: caseStudySection2Image,
  }
  const section3Data = {
    title: caseStudySection3Title,
    description: caseStudySection3Description,
    image: caseStudySection3Image,
  }

  return (
    <Layout>
      <SEO
        title={ogTitle || caseStudyHeroTitle}
        description={ogDescription || caseStudyHeroTitle}
        image={image?.fluid?.src}
      />
      <Banner data={bannerData} customNav={globalData?.state?.customNav} />
      <ProblemStatement data={problemStatementData} />
      <SectionBlock data={section1Data} />
      <SectionBlock data={section2Data} alternate />
      <Contact />
      <SectionBlock data={section3Data} />
      <CompanyHighlight />
      <FeaturedWork data={caseStudyFeaturedWork} />
      <ContactUs simple />
    </Layout>
  )
}

export const query = graphql`
  query CaseStudyTemplatePageQuery($slug: String) {
    content: contentfulWorkShowcase(slug: { eq: $slug }) {
      ogTitle
      ogDescription
      image {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      caseStudyHeroTitle {
        json
      }
      caseStudyHeroImage {
        title
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      caseStudyProblem {
        caseStudyProblem
      }
      caseStudySolution {
        caseStudySolution
      }
      caseStudyResult {
        caseStudyResult
      }
      caseStudyTestimonial
      caseStudyDeliverables
      caseStudyTestimonialAuthor {
        json
      }
      caseStudySection1Title
      caseStudySection1Description {
        json
      }
      caseStudySection1Image {
        title
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      caseStudySection2Title
      caseStudySection2Description {
        json
      }
      caseStudySection2Image {
        title
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      caseStudySection3Title
      caseStudySection3Description {
        json
      }
      caseStudySection3Image {
        title
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      caseStudyFeaturedWork {
        id
        slug
        image {
          id
          title
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`

export default CaseStudyTemplate
