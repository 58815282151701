import React from "react"

// Components
import Background from "../../backgroundImage"
import ShowcaseTile from "../../showcaseTile"

const FeaturedWork = ({ data }) => {
  return (
    <Background className="text-white">
      <div className="container max-w-6xl px-4 py-8 mx-auto md:pb-12 md:px-8 xl:px-0">
        <h1 className="mb-4 text-2xl uppercase md:text-4xl md:text-left siteFont">
          Other Featured Work
        </h1>

        <div className="grid w-full grid-cols-1 gap-8 md:grid-cols-3">
          {data.map(node => (
            <ShowcaseTile data={node} />
          ))}
        </div>
      </div>
    </Background>
  )
}

export default FeaturedWork
