import React from "react"
import { Link } from "gatsby"

function ContactUs() {
  return (
    <div
      className={`max-w-6xl mx-auto px-4 md:px-12 ContactUs isolated md:rounded-lg mt-4`}
    >
      <div className="py-8 md:flex md:justify-around md:items-center md:px-12">
        <div className="text-white md:w-3/4">
          <h1 className="text-3xl font-medium siteFont">
            Want us to audit your site?
            <br /> Contact us today.
          </h1>
        </div>
        <Link
          to="/contact"
          className="inline-block w-auto px-8 py-4 mt-8 text-xl font-bold text-center uppercase bg-white rounded-full btn md:w-1/5 focus:outline-none md:mt-0 siteFont"
        >
          Contact Us
        </Link>
      </div>
    </div>
  )
}

export default ContactUs
