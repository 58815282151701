import React from "react"
import Img from "gatsby-image"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

// Components
import Background from "../../backgroundImage"

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <h1 className="text-3xl font-bold text-center uppercase md:text-5xl md:text-left siteFont">
        {children}
      </h1>
    ),
  },
}

const Banner = ({
  data: { caseStudyHeroTitle, caseStudyHeroImage },
  customNav,
}) => {
  return (
    <Background className={`text-white ${customNav ? "pt-4 md:pt-8" : ""}`}>
      <div className="container flex flex-col justify-center max-w-6xl px-4 pt-16 mx-auto md:flex-row md:pt-20 md:px-8 xl:px-0 md:items-center">
        <div className="w-full md:w-1/2">
          <div className="my-2">
            {documentToReactComponents(caseStudyHeroTitle.json, options)}
          </div>
        </div>
        <div className="w-full md:w-1/2">
          <Img
            fluid={caseStudyHeroImage.fluid}
            alt={caseStudyHeroImage.title}
            fade={false}
            loading="eager"
          />
        </div>
      </div>
    </Background>
  )
}

export default Banner
