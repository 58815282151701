import React from "react"
import Img from "gatsby-image"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const options = {
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => (
      <h1 className="my-4 text-3xl font-bold text-white siteFont md:text-5xl">
        {children}
      </h1>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <h2 className="my-4 text-xl font-bold text-white siteFont md:text-4xl">
        {children}
      </h2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <h3 className="my-4 text-lg font-bold text-white siteFont md:text-3xl">
        {children}
      </h3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <h4 className="my-4 text-base font-bold text-white siteFont md:text-xl">
        {children}
      </h4>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <h5 className="my-4 font-bold text-white siteFont md:text-lg">
        {children}
      </h5>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <h6 className="my-4 font-bold text-white siteFont md:text-lg">
        {children}
      </h6>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p className="my-4 md:text-lg">{children}</p>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul className="pl-6 list-disc list-outsite md:pl-12">{children}</ul>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <ul className="pl-6 list-decimal list-outsite md:pl-12">{children}</ul>
    ),
    [BLOCKS.HR]: () => <hr className="my-6 md:my-16 xl:mx-auto" />,
  },
  renderMark: {
    [MARKS.UNDERLINE]: text => <u>{text}</u>,
  },
}

const SectionBlock = ({ data: { title, description, image }, alternate }) => {
  return (
    <div
      className={`container flex flex-col justify-center max-w-6xl px-4 py-8 md:px-8 xl:px-0 mx-auto text-white ${
        alternate ? "md:flex-row-reverse" : "md:flex-row"
      } md:py-12 md:px-0 md:items-center`}
    >
      <div className={`w-full ${alternate ? "md:w-1/2" : "md:w-3/5"}`}>
        <div className={`w-full ${alternate ? "md:pl-8" : "md:w-11/12"}`}>
          <h1 className="mb-6 text-2xl text-center uppercase md:text-4xl md:text-left siteFont">
            {title}
          </h1>
          <p className="Gray">
            {documentToReactComponents(description.json, options)}
          </p>
        </div>
      </div>
      <div className={`w-full ${alternate ? "md:w-1/2" : "md:w-2/5"}`}>
        <Img fluid={image.fluid} alt={image.title} />
      </div>
    </div>
  )
}

export default SectionBlock
