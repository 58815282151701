import React from "react"

// Assets
import logo from "../../../assets/logo-inverted.svg"

function CompanyHighlight() {
  return (
    <div className={`mx-auto px-4 md:px-12 caseStudyHighlight mt-4`}>
      <div className="flex flex-col items-center justify-center py-8 md:flex-row">
        <img
          src={logo}
          alt="Kokopelli Logo"
          className="mb-4 md:mb-0 md:mr-6 "
        />
        <h1 className="text-2xl font-medium text-center uppercase md:text-left md:text-4xl siteFont">
          Skill and service together.
          <br /> That's what Kokopelli offers you
        </h1>
      </div>
    </div>
  )
}

export default CompanyHighlight
